import React, { useState, useMemo, memo } from 'react';
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, FormText, FormFeedback, Container, CustomInput, Collapse } from 'reactstrap';
import { Header, Dialog, BondTable, DeleteIcon, ImportExport, BondCodeLink, LsWrap, TableNotice, DemoNotice, LinksToCrawl, ExtraColumns, Filters } from './modules'
import { Functions } from './functions';
import { connect, Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { bonds, currentSorting, allBonds, calculatedBonds, ndflFreeBonds, tableLoaded, priceType } from './store/reducers';
import { useTranslation } from 'react-i18next';
import { useXstateForButton } from 'xstate-button';


const reducer = combineReducers({
    bonds,
    currentSorting,
    allBonds,
    calculatedBonds,
    ndflFreeBonds,
    tableLoaded,
    priceType
});
const store = createStore(reducer);

export const MyBonds = () => {
    const { t } = useTranslation();
    const header = t('MyBonds.header');

    return (
        <Provider store={store}>
            <Header>{header}</Header>
            <Text />
            <LsWrap>
                <MyBondsFilters header={header} />
                <MyBondsTable />
                <DemoNotice storage="myBonds" />
                <TableNotice />
            </LsWrap>
            <LinksToCrawl links={['aboutCode']} />
        </Provider>
    );
};

let MyBondsFilters = ({ header, dispatch }) => {
    const fillBonds = () => Functions.fillBonds("myBonds", dispatch);
    return (
        <Filters read={fillBonds} minified={true} myBonds={true}>
            <span className="m-1 inlineBlock">
                <AddBond />
            </span>
            <span className="m-1 inlineBlock">
                <ImportExport storage="myBonds" downloadName={header} />
            </span>
        </Filters>
    );
};
MyBondsFilters = connect(state => state)(MyBondsFilters);

const Text = () => {
    const { t } = useTranslation();
    return (
       <Container>
          <p>
             {t('MyBonds.text.part1')}
          </p>
          <p className="mb-5">
             {t('MyBonds.text.part2')}
          </p>
       </Container>
    );
};

const myBondsColumns = (extraColumns) => {
    if (extraColumns) {
        return [
            'bondCard',
            'rate',
            'amount',
            'purchasePercent',
            'currentPercent',
            'offer',
            'lowHighPrice',
            'averagePrice',
            'calculatedDaysOwnDiff',
            'realRate',
            'currentYearRevenuePercent',
            'remainRepaymentRate',
            'revenueByRepaymentPercent',
            'currentRevenuePercent',
            'currentSellPeriod',
            'currentWaitPeriod',
            'nkdPeriod',
            'buyNkdPercent',
            'nkdPercent',
            'tradeCount',
            'totalTradeVolume',
            'lastTradeVolume',
            'lastTradeTime',
            'totalBid',
            'totalOffer',
            'bidOfferRatio',
            'lotValue',
            'listing',
            'daysTillCoupon',
            'daysTillRepayment',
            'purchaseDate',
            'code',
            'actions'
        ];
    }
    else {
        return [
            'bondCard',
            'rate',
            'amount',
            'purchasePercent',
            'currentPercent',
            'offer',
            'averagePrice',
            'calculatedDaysOwnDiff',
            'realRate',
            'currentYearRevenuePercent',
            'remainRepaymentRate',
            'revenueByRepaymentPercent',
            'currentRevenuePercent',
            'currentSellPeriod',
            'currentWaitPeriod',
            'tradeCount',
            'totalTradeVolume',
            'nkdPercent',
            'lotValue',
            'daysTillCoupon',
            'daysTillRepayment',
            'purchaseDate',
            'code',
            'actions'
        ];
    }
};

let MyBondsTable = ({ bonds, currentSorting, tableLoaded, dispatch }) => {
    const { t } = useTranslation();
    const [extraColumns, setExtraColumns] = useState(() => !!window.localStorage.extraColumns);
    const headers = useMemo(() => Functions.mapColumns(myBondsColumns(extraColumns), Functions.getTableColumns(t)), [extraColumns, t]);

    return (
        <div>
            <ExtraColumns extraColumns={extraColumns} setExtraColumns={setExtraColumns} />
            <BondTable {...{ bonds, headers, currentSorting, dispatch, TableRow, tableLoaded, deleteProp: 'id', extraColumns }} />
        </div>
    ); 
};
MyBondsTable = connect(state => state)(MyBondsTable);

const TableRow = memo(({ bond, deleteBond, extraColumns }) => {
    const { t } = useTranslation();
    if (!bond)
        return null;

    const handleDelete = (id) => {
        if (Functions.confirmDelete(t) && id) {
           const bonds = Functions.getMyBonds().filter(bond => bond.id !== id);
           Functions.updateMyBonds(bonds);
           deleteBond(id);
        }
    };

    let rowClass = '';
    if (bond.sellNow)
        rowClass = 'rowSellNow';
    return (
        <tr className={rowClass}>
            {Functions.mapRows(myBondsColumns(extraColumns), Functions.getRowValues(t, bond))}
            <td key={100} className="bondsTableActions">
                 <EditBond bond={bond} />{' '}
                 <span className="ml-2" onClick={() => handleDelete(bond.id)}>
                     <DeleteIcon />
                 </span>
             </td>
        </tr>
    );
});

 const AddBond = () => {
    const { t } = useTranslation();
    const templateProps = {
        header: t('BondDialog.header.add'),
        toggleElement: <Button color="info" outline data-test-id="addBond">{t('Buttons.addBond')}</Button>,
        saveButtonName: 'add'
    };
    return <AddBondTemplate { ...templateProps } />;
 };

 const EditBond = ({ bond }) => {
    const { t } = useTranslation();
    const templateProps = {
        header: t('BondDialog.header.edit'),
        toggleElement: <img src="/edit.png" data-test-id="editBond" />,
        saveButtonName: 'save',
        edit: true,
        bond: bond
    };
    return <AddBondTemplate { ...templateProps } />;
 };

let AddBondTemplate = ({ bond, currentSorting, dispatch, header, toggleElement, saveButtonName, edit, priceType, ndflFreeBonds }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [codeMessage, setCodeMessage] = useState();
    const [invalidPrice, setInvalidPrice] = useState(false);
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [autoNkd, setAutoNkd] = useState(true);
    const toggleDialog = () => setOpen(!open);
    const onClosed = () => {
        setInvalidCode(false);
        setInvalidPrice(false);
        setInvalidAmount(false);
        setInvalidDate(false);
    };
    const dialogProps = { header: header, open, toggleDialog, onClosed };
    const [codeRef, code] = Functions.useRef(node => {
        if (bond)
            node.value = bond.code;
        if (edit)
            node.disabled = true;
    }, [bond]);
    const [purchasePercentRef, purchasePercent] = Functions.useRef(node => {
        if (bond) {
            node.value = bond.purchasePercent;
        }
    }, [bond]);
    const [amountRef, amount] = Functions.useRef(node => {
        if (bond)
            node.value = bond.amount;
    }, [bond]);
    const [userNkdRef, userNkd] = Functions.useRef(node => {
        if (bond && Functions.isSet(bond.userNkd)) {
            node.value = bond.userNkd;
            setAutoNkd(false);
        }
    }, [bond]);
    const [purchaseDateRef, purchaseDate] = Functions.useRef(node => {
        if (bond)
            node.value = bond.purchaseDate;
    }, [bond]);

    const [ start, buttonName, buttonDisabled ] = useXstateForButton(async (context, event) => {
        const { code, purchasePercent, purchaseDate, amount, userNkd } = event;
        let bonds = Functions.getMyBonds();
        let newBond = {
            code: code.toUpperCase(),
            purchasePercent: parseFloat(purchasePercent),
            purchaseDate: purchaseDate,
            amount: parseInt(amount)
        };
        if (Functions.isSet(userNkd))
            newBond.userNkd = parseFloat(userNkd);

        const isNdflFree = (bond) => {
            let ndflFree = false;
            if (bond.sector === 4 || new RegExp('^SU', 'i').test(bond.code))
                ndflFree = true;
            else {
                if (Functions.isSet(ndflFreeBonds) && ndflFreeBonds.length)
                    ndflFree = ndflFreeBonds.includes(bond.code);
            }
            return ndflFree;
        };

        return Functions.updateBond(newBond).then(updatedBond => {
            const dispatchBond = (type, bond) => dispatch({ type: type, bond: bond });
            newBond.repaymentDate = updatedBond.repaymentDate;
            let id;
            if (edit) {
                id = bond.id;
                updatedBond.id = id;
                newBond.id = id;
                newBond.ndflFree = isNdflFree(updatedBond);
                updatedBond.ndflFree = newBond.ndflFree;
                bonds = bonds.map(bond => {
                    if (bond.id === id)
                        return newBond;
                    else
                        return bond;
                });
                dispatchBond('updateAllBonds', updatedBond);
            }
            else {
                id = Date.now();
                updatedBond.id = id;
                newBond.id = id;
                newBond.ndflFree = isNdflFree(updatedBond);
                updatedBond.ndflFree = newBond.ndflFree;
                bonds.push(newBond);
                dispatchBond('addToAllBonds', updatedBond);
            }

            Functions.updateMyBonds(bonds);
            updatedBond = Functions.calculate(updatedBond, priceType);
            if (edit) {
                dispatchBond('updateCalculatedBonds', updatedBond);
                dispatchBond('updateBond', updatedBond);
            }
            else {
                dispatchBond('addToCalculatedBonds', updatedBond);
                dispatchBond('addBond', updatedBond);
                Functions.setYandexGoal('MyBondAdded');
            }
            dispatch({
                type: 'applySorting',
                sorting: currentSorting
            });
            toggleDialog();
        }).catch(error => {
            setInvalidCode(true);
            setCodeMessage(t('BondDialog.errors.wrongCode'));
        });
    }, Functions.actionButtonNames(saveButtonName, t));

    const handleAddition = (event) => {
        event.preventDefault();
        if (!code.value.length)
            setCodeMessage(t('BondDialog.errors.cannotBeEmpty'));
        setInvalidCode(!code.value.length);
        setInvalidPrice(!purchasePercent.value.length);
        setInvalidAmount(!amount.value.length);
        setInvalidDate(!purchaseDate.value.length);

        if (code.value.length && purchaseDate.value.length && purchasePercent.value.length && amount.value.length) {
            const values = {
                code: code.value,
                purchasePercent: purchasePercent.value,
                purchaseDate: purchaseDate.value,
                amount: amount.value
            };
            if (!autoNkd && userNkd.value.length)
                values.userNkd = userNkd.value;
            start(values);
        }
    };

    let tabIndex = 1;
    const autoNkdClick = () => setAutoNkd(!autoNkd);
    const monitorAutoNkd = () => { 
        if (edit)
            setAutoNkd(true);
    };

    return (
        <span>
            <span onClick={toggleDialog}>{toggleElement}</span>
            <Dialog { ...dialogProps }>
                <Form name='addBond' onSubmit={handleAddition}>
                    <FormGroup>
                        <Label>{t('BondDialog.labels.code')}</Label>
                        <Input name="code" invalid={invalidCode} type="text" innerRef={codeRef} autoComplete="off" tabIndex={tabIndex++} />
                        <FormFeedback valid={!invalidCode}>{codeMessage}</FormFeedback>
                        <FormText>{t('BondDialog.hints.inputCode.myBonds')} (<BondCodeLink t={t} />)</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('BondDialog.labels.price')}</Label>
                        <InputGroup>
                            <Input name="cost" invalid={invalidPrice} type="number" min="0.001" max="500" step="0.001" innerRef={purchasePercentRef} autoComplete="off" onChange={monitorAutoNkd} tabIndex={tabIndex++} />
                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                            <FormFeedback valid={!invalidPrice}>{t('BondDialog.errors.cannotBeEmpty')}</FormFeedback>
                        </InputGroup>
                        <FormText>{t('BondDialog.hints.price')}</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('BondDialog.labels.amount')}</Label>
                        <InputGroup>
                            <Input name="amount" invalid={invalidAmount} type="number" min="1" innerRef={amountRef} autoComplete="off" onChange={monitorAutoNkd} tabIndex={tabIndex++} />
                            <InputGroupAddon addonType="append">{t('Endings.pieces')}</InputGroupAddon>
                            <FormFeedback valid={!invalidAmount}>{t('BondDialog.errors.cannotBeEmpty')}</FormFeedback>
                        </InputGroup>
                        <FormText>{t('BondDialog.hints.amount')}</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('BondDialog.labels.date')}</Label>
                        <Input name="date" invalid={invalidDate} type="date" innerRef={purchaseDateRef} autoComplete="off" onChange={monitorAutoNkd} tabIndex={tabIndex++} />
                        <FormFeedback valid={!invalidDate}>{t('BondDialog.errors.cannotBeEmpty')}</FormFeedback>
                        <FormText>{t('BondDialog.hints.date')}</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label className="mr-4">{t('BondDialog.labels.nkd')}</Label>
                        <CustomInput type="switch" name="autoNkd" id="autoNkd" label={t('BondDialog.labels.nkdSwitch')} className="noSelect autoNkd" onChange={autoNkdClick} checked={autoNkd} />
                        <Collapse isOpen={!autoNkd}>
                            <InputGroup>
                                <Input name="userNkd" type="number" min="0" step="0.01" innerRef={userNkdRef} autoComplete="off" tabIndex={tabIndex++} />
                                <InputGroupAddon addonType="append">{t('Endings.rub')}</InputGroupAddon>
                            </InputGroup>
                            <FormText>{t('BondDialog.hints.nkd')}</FormText>
                        </Collapse>
                    </FormGroup>
                    <FormGroup className="alignRight">
                        <Button name="save" disabled={buttonDisabled} color="info" tabIndex={tabIndex++}>{buttonName}</Button>
                    </FormGroup>
                </Form>
            </Dialog>
        </span>
   );
 };
 AddBondTemplate = connect(state => state)(AddBondTemplate);