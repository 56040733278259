import React from 'react';
import { Header, FeedbackForm } from './modules';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';


export const FeedbackTest = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Header noindex={true}>{t('Feedback.header')}</Header>
            <Container>
                <FeedbackForm test={true} />
            </Container>
        </div>
    );
};