import React, { useState, useEffect, useCallback, memo } from 'react';
import { createPortal } from 'react-dom';
import { Link, NavLink as RNavLink, withRouter } from 'react-router-dom';
import { Table as STable, Button, Modal, ModalHeader, ModalBody, Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Form, Input, FormFeedback, FormText, Alert, Col, Row, UncontrolledCollapse, CustomInput, InputGroupAddon, FormGroup, Label, InputGroup, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Functions } from './functions.js';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { useXstateForButton } from 'xstate-button';
import { BondCard } from './BondCard';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { createChart } from 'lightweight-charts';


export const ExternalLink = memo(({ name, url }) => <a href={url} target="_blank" rel="noopener noreferrer nofollow">{name}</a>);

export const MoexButton = ({ bond }) => {
   const { t } = useTranslation();
   const url = 'https://www.moex.com/' + Functions.getCurrentLanguage() + '/issue.aspx?code=' + bond.code;
   const click = () => window.open(url, "_blank");

   return <Button color="info" outline onClick={click}>{t('BondCard.openOnMoex')}</Button>
};

export const BondCardLink = ({ bond, type }) => {
   const [open, setOpen] = useState(false);
   const toggleDialog = () => setOpen(!open);
   const dialogProps = { header: <h1 className="bondCardHeading">{Functions.getLocalBondName(bond)}</h1>, open, toggleDialog, fullScreen: true, contentClassName: "bondCardBg", noHeaderLine: true };

   return (
      <>
         <span className="likeAnchor" onClick={toggleDialog}>{Functions.getLocalBondName(bond)}</span>
         <Dialog { ...dialogProps }>
            <BondCard bond={bond} type={type} />
         </Dialog>
      </>
   );
};
 
export const Table = ({ header, rows, loaded }) => {
   const { t } = useTranslation();
   let info;
   if (loaded)
      info = t('Table.noData');
   else
      info = t('Table.loading');
   const tableInfo = <div className="tableNoData">{info}</div>;

   const [height, setHeight] = useState();
   useEffect(() => {
      const calculateHeight = () => {
         let newHeight = window.innerHeight || document.documentElement.clientHeight;
         if (newHeight) {
            newHeight = newHeight - 75 + "px";
            setHeight(newHeight);
         }
      };
      calculateHeight();
      window.addEventListener('resize', calculateHeight);

      return () => window.removeEventListener('resize', calculateHeight);
   }, []);

   return (
      <div>
         <div className="fakeBorder"></div>
         <div className="table-responsive" style={{ maxHeight: height, minHeight: '220px' }}>
            <STable className="bondsTable">
               <tbody>
                  {header}
                  {rows.length !== 0 && rows}
               </tbody>
            </STable>
            {rows.length === 0 && tableInfo}
         </div>
      </div>
   );
 };

 export const TableHeader = memo(({ headers, currentSorting, applySorting }) => {
   const { t } = useTranslation();
   const tooltips = Functions.getBondTooltips(t);

   const headerClick = (sortingTypes) => {
      if (!sortingTypes || !sortingTypes.length)
         return;

      const [desc, asc] = sortingTypes;
      let newSorting;
      if (currentSorting === desc)
         newSorting = asc;
      else
         newSorting = desc;
      applySorting(newSorting);
   };

   const headerRow = headers.map((header, index) => {
      const pSorting = currentSorting;
      const hSorting = header.sorting;
      let name = header.name;
      let code;
      if (hSorting && hSorting.includes(pSorting)) {
         if (pSorting === hSorting[0])
            code = '8595';
         else
            code = '8593';
         if (code)
            name += String.fromCharCode(code);
      }
      let className = 'noSelect';
      if (hSorting)
         className += ' pointer';
      const title = tooltips[header.field];
      const th = <th key={index} className={className} onClick={() => headerClick(header.sorting)}>{name}</th>;
      if (Functions.isSet(title))
         return <Btooltip key={index} title={title} enterDelay={Functions.tooltipDelay()}>{th}</Btooltip>
      else
         return th;
   });

   return <tr>{headerRow}</tr>;
 });

 export const AddPriceRefreshNotice = (props) => createPortal(<PriceRefreshNotice {...props} />, document.body);

//  export let RefreshButton = ({ storage, dispatch }) => {
//    const { t } = useTranslation();
//    const [visible, setVisible] = useState(false);
//    const [ start, buttonName, buttonDisabled ] = Functions.useRequestMachine(async (context, event) => {
//       window.localStorage.removeItem('bondsInfo');
//       return Functions.fillBonds(storage, dispatch).then(() => setVisible(true));
//    }, Functions.actionButtonNames('refreshPrices', t));

//    return (
//       <span>
//          <AddPriceRefreshNotice visible={visible} setVisible={setVisible} />
//          <Button color="success" outline disabled={buttonDisabled} onClick={start}>{buttonName}</Button>
//       </span>
//    ); 
//  };
//  RefreshButton = connect(state => state)(RefreshButton);

 export const Dialog = ({ header, open, toggleDialog, onClosed, children, size, fullScreen, contentClassName, noHeaderLine }) => {
   const zeroTimeout = { timeout: 0 };
   let className;
   if (fullScreen === true)
      className = 'fullScreen';
   let modalHeaderClass;
   if (noHeaderLine)
      modalHeaderClass = 'noHeaderLine';

   return (
      <Modal className={className} contentClassName={contentClassName} size={size} centered isOpen={open} toggle={toggleDialog} modalTransition={zeroTimeout} backdropTransition={zeroTimeout} onClosed={onClosed}>
      <ModalHeader className={modalHeaderClass} toggle={toggleDialog}>{header}</ModalHeader>
      <ModalBody>
         {children}
      </ModalBody>
      </Modal>
   );
};

export const Header = ({ children: name, title, description, keywords, noindex, component }) => {
   const { t } = useTranslation();
   const finalTitle = title ? title : name;
   const keywordsCode = <meta name="keywords" content={keywords} />
   const descriptionCode = <meta name="description" content={description} />;
   const url = "https://" + t('Site.url') + window.location.pathname;
   const metaNoIndex = <meta name="robots" content="noindex" />;

   return (
      <div>
         <Helmet>
            <title>{finalTitle}</title>
            <meta name="title" content={finalTitle} />
            {Functions.isSet(keywords) && keywordsCode}
            {Functions.isSet(description) && descriptionCode}
            {noindex && metaNoIndex}
            <link rel="canonical" href={url} />
         </Helmet>
         <Container>
            <h1 className="heading">{component ? component : name}</h1>
         </Container>
      </div>
   );
};

export const BondTable = memo(({ bonds, headers, currentSorting, dispatch, TableRow, tableLoaded, deleteProp, extraColumns }) => {
   const applySorting = useCallback((newSorting) => {
      dispatch({
          type: 'applySorting',
          sorting: newSorting
      });
   }, [dispatch]);

   const deleteBond = useCallback((value) => {
      const deleteDispatch = (type) => dispatch({ type: type, prop: deleteProp, [deleteProp]: value });
      deleteDispatch('deleteBond');
      deleteDispatch('deleteFromAllBonds');
      deleteDispatch('deleteFromCalculatedBonds');
   }, [dispatch, deleteProp]);

   const header = <TableHeader { ...{ headers, currentSorting, applySorting } } />;
   let rows;
   let sortedBonds;
   if (currentSorting)
      sortedBonds = Functions.sortItems(bonds.slice(), currentSorting);
   else
      sortedBonds = bonds;
   if (sortedBonds)
      rows = sortedBonds.map((bond, index) => (<TableRow {...{ key: index, bond, deleteBond, extraColumns }} />));
   else
      rows = [];
   return <Table header={header} rows={rows} loaded={tableLoaded} />;
});

export const TopMenu = () => {
   const { t } = useTranslation();
   const [collapseOpen, setCollapseOpen] = useState(false);
   const toggleCollapse = () => setCollapseOpen(!collapseOpen);
   const [dropdownOpen, setDropdownOpen] = useState(false);
   const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
   const navProps = {
      activeClassName: 'topMenuActive',
      tag: RNavLink
   };
 
   return (
       <Navbar dark expand="lg">
         <NavbarBrand {...navProps} exact to="/">{t('Site.name')}</NavbarBrand>
         <NavbarToggler onClick={toggleCollapse} />
         <Collapse isOpen={collapseOpen} navbar>
           <Nav className="mr-auto" navbar>
               <NavItem>
                  <NavLink {...navProps} to="/calculator/">{t('Calculator.menuLink')}</NavLink>
               </NavItem>
               <NavItem>
                  <NavLink {...navProps} to="/findBonds/">{t('FindBonds.header')}</NavLink>
               </NavItem>
               <NavItem>
                  <NavLink {...navProps} to="/newBonds/">{t('NewBonds.header')}</NavLink>
               </NavItem>
               <NavItem>
                  <NavLink {...navProps} to="/myBonds/">{t('MyBonds.header')}</NavLink>
               </NavItem>
           </Nav>
           <Nav className="ml-auto" navbar>
               <NavItem>
                  <FeeDialog />
               </NavItem>
               <NavItem>
                  <NavLink {...navProps} to="/feedback/">{t('Feedback.header')}</NavLink>
               </NavItem>
               <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle nav caret />
                  <DropdownMenu right className="aquaBg">
                     <NavLink onClick={toggleDropdown} tag={RNavLink} className="navDropdownItem" to="/privacyPolicy/">{t('Privacy.header')}</NavLink>
                  </DropdownMenu>
               </Dropdown>
           </Nav>
         </Collapse>
       </Navbar>
   );
};

export const DeleteIcon = memo(() => <img src="/delete.png" />);

export let ImportExport = ({ storage, downloadName, dispatch }) => {
   const { t } = useTranslation();
   const [open, setOpen] = useState(false);
   const [invalid, setInvalid] = useState(false);
   const toggleDialog = () => setOpen(!open);
   const onClosed = () => setInvalid(false);
   const moduleName = t('Import.button');
   const dialogProps = { header: moduleName, open, toggleDialog, onClosed };
   const exportData = localStorage.getItem(storage);
   const uri = "data:text/json;charset=utf-8," + encodeURI(exportData);
   const [fileRef, fileInput] = Functions.useRef();

   const [ start, buttonName, buttonDisabled ] = useXstateForButton((context, event, resolve) => {
      try {
         const { file } = event;
         const reader = new FileReader();
         reader.readAsText(file);
         reader.onload = (event) => {
            const text = reader.result;
            const myPattern = /purchasePercent/i;
            let correctFile = true;
            switch (storage) {
               case "newBonds":
                  if (myPattern.test(text))
                     correctFile = false;
                  break;
               case "myBonds":
                  if (!myPattern.test(text))
                     correctFile = false;
                  break;
               default:
                  throw new Error('Unexpected storage!');
            }
            
            if (correctFile) {
               if (window.confirm(t('Import.warnings.erase'))) {
                     localStorage.setItem(storage, reader.result);
                     Functions.fillBonds(storage, dispatch).then(() => {
                        toggleDialog();
                        resolve();
                        Functions.setYandexGoal('AppliedFile-' + storage);
                        window.location.reload();
                     });
               }
               else
                  resolve();
            }
            else {
               resolve();
               alert(t('Import.warnings.wrongFile'));
            }
         };
      }
      catch (error) {
         resolve();
         alert(t('Import.warnings.readError'));
      }
  }, { ...Functions.actionButtonNames('apply', t), promise: true });

   const handleSubmit = (event) => {
       event.preventDefault();
       const files = fileInput.files;
       if (files && files[0]) {
           setInvalid(false);
           start({ file: files[0] });
       }
       else
           setInvalid(true);
   };

   const downloadClick = () => Functions.setYandexGoal('DownloadedFile-' + storage);

   return (
       <span>
           <Button color="danger" outline onClick={toggleDialog}>{moduleName}</Button>
           <Dialog { ...dialogProps }>
               <div className="mb-4 mt-2">
                  <div className="alignCenter">
                     <Button color="info" id="dialogWhyCollapse">{t('Buttons.whyIsThat')}</Button>
                  </div>
                  <UncontrolledCollapse toggler="#dialogWhyCollapse" className="mt-3">
                     {t('Import.content.text')}
                  </UncontrolledCollapse>
               </div>
               <hr />
               <div className="my-4">
                  <h5>{t('Import.content.download.header')}</h5>
                  <a href={uri} download={downloadName + ".json"} onClick={downloadClick}>{t('Import.content.download.saveLink.part1')}</a>{t('Import.content.download.saveLink.part2')}
               </div>
               <hr />
               <div className="my-4">
                  <h5>{t('Import.content.upload.header')}</h5>
                  <Form onSubmit={handleSubmit} className="mt-3" >
                     <Input type="file" name="file" invalid={invalid} innerRef={fileRef} />
                     <FormFeedback valid={!invalid}>{t('Import.content.upload.noFile')}</FormFeedback>
                     <FormText color="muted">
                        {t('Import.content.upload.hint')}
                     </FormText>
                     <div className="alignRight">
                        <Button color="info" disabled={buttonDisabled} outline>{buttonName}</Button>
                     </div>
                  </Form>
               </div>
           </Dialog>
       </span>
   );
};
ImportExport = connect(state => state)(ImportExport);

// export let DemoButton = ({ storage, dispatch, priceType }) => {
//    const { t } = useTranslation();
//    const [ start, buttonName, buttonDisabled ] = Functions.useRequestMachine(async (context, event) => Functions.fillDemoData(storage, dispatch, priceType), Functions.actionButtonNames('fillDemo', t));
//    return (
//        <div className="alignCenter m-3">
//            <Button color="warning" outline disabled={buttonDisabled} onClick={start}>{buttonName}</Button>
//        </div>
//    );
// };
// DemoButton = connect(state => state)(DemoButton);

export const AlertNotice = ({ visible, setVisible, color, children }) => {
   if (visible)
      Functions.useTimeout(() => setVisible(false), 2000);
   
   return (
      <Alert color={color} className="infoNotice" isOpen={visible}>
         {children}
      </Alert>
   );
};

export const PriceRefreshNotice = (props) => {
   const { t } = useTranslation();

   return (
      <AlertNotice color='success' {...props} >
         {t('Alerts.priceRefresh')}
      </AlertNotice>
   );
};

export const FilterResultNotice = (props) => {
   const { t } = useTranslation();

   return (
      <AlertNotice color='info' {...props} >
         {t('Alerts.filtersApplied') + props.count}.
      </AlertNotice>
   );
};

export const Footer = () => {
   const { t } = useTranslation();
   const currentYear = new Date().getFullYear();

   return (
      <div className="copyright mt-5 mb-1">
         &copy; bondcalc.ru 2020 - {currentYear}
      </div>
   );
};

export const InfoBlock = ({ name, t, children }) => {
   const header = name + ".header";
   const text = name + ".text";

   return (
      <Col md="5" className="mx-auto mt-5">
         <h2 className="heading blockHeading">{t(header)}</h2>
         <p><Trans i18nKey={text}>{children}</Trans></p>
      </Col>
   );
};

export const BondCodeLink = ({ t }) => <Link to="/aboutCode/" target="_blank">{t('BondDialog.hints.whatsThis')}</Link>;

export const LsWrap = ({ children }) => {
   const { t } = useTranslation();
   if (!Functions.supportsLocalStorage()) {
      return (
         <Container>
            <Row className="justify-content-center">
               <Col lg="8">
                  <div className="noLs">
                     {t('Alerts.noLs')}
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
   else
      return children;
};

export const TableNotice = ({ children }) => {
   const { t } = useTranslation();

   return (
      <Container>
         <h2 className="heading">{t('TableNotice.header')}</h2>
         {children && <p>{children}</p>}
         <p>
            <Trans i18nKey="TableNotice.part1"></Trans>
         </p>
         <p>
            <Trans i18nKey="TableNotice.part2">text <span className="putOffer">text</span> text</Trans>
         </p>
         <p>
            <Trans i18nKey="TableNotice.part3">text <span className="redeemed">text</span> text</Trans>
         </p>
      </Container>
   );
};

class ScrollToTop extends React.Component {
   componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname)
         window.scrollTo(0, 0);
   }

   render() {
      return null;
   }
}
ScrollToTop = withRouter(ScrollToTop);
export {ScrollToTop};

export const CookieNotice = () => {
   const { t } = useTranslation();
   const [visible, setVisible] = useState(false);
   if (Functions.isCrawlingMode())
      return null;

   const setCookieAccepted = (value) => Functions.saveCookie('cookieAccepted', value);
   const acceptCookies = () => {
      setCookieAccepted('1');
      setVisible(false);
   };
   const cookieAccepted = Functions.readCookie('cookieAccepted');
   if (cookieAccepted !== '1' && !visible)
      setVisible(true);

   if (visible) {
      return (
         <div className="cookieNotice py-2 px-3 alignCenter">
            <span className="cookieNoticeText mr-3">{t('Alerts.cookieNotice')}</span>
            <Link to="/privacyPolicy/" className="mr-3">{t('Buttons.details')}</Link>
            <Button color="secondary" onClick={acceptCookies}>{t('Buttons.iSee')}</Button>
         </div>
      );
   }
   else
      return null;
};

export let DemoNotice = ({ storage, dispatch, priceType, realRatePeriod }) => {
   const { t } = useTranslation();
   const [visible, setVisible] = useState(false);
   if (Functions.isCrawlingMode())
      return null;

   const cookieNotice = storage + 'Notice';
   const cookieFilled = storage + 'DemoFilled';

   const demoFilled = Functions.readCookie(cookieFilled);
   if (!demoFilled || demoFilled !== '1') {
      const bonds = Functions.getStorageBonds(storage);
      if (bonds.length === 0)
         Functions.fillDemoData(storage, dispatch, priceType, realRatePeriod);
      else
         Functions.saveCookie(cookieNotice, '1');
      Functions.saveCookie(cookieFilled, '1');
   }

   const noticeClosed = Functions.readCookie(cookieNotice);
   if ((!noticeClosed || noticeClosed !== '1') && !visible)
      setVisible(true);

   const onClose = () => {
      setVisible(false);
      Functions.saveCookie(cookieNotice, '1');
      Functions.saveCookie(cookieFilled, '1');
   };

   return (
      <Container>
         <div className="alignCenter">
            <Alert color="warning" className="inlineBlock mt-3" isOpen={visible} toggle={onClose}>
               {t('Alerts.demo')}
            </Alert>
         </div>
      </Container>
   );
};
DemoNotice = connect(state => state)(DemoNotice);

export const GoogleAnalytics = () => {
   const apply = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156970224-1');
   };

   if (!Functions.isCrawlingMode())
      apply();

   return null;
};

export const YandexMetrica = () => {
   const metrica = function(m,e,t,r,i,k,a) {
      m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      k=e.createElement(t);
      a=e.getElementsByTagName(t)[0];
      k.async=1;
      k.src=r;
      a.parentNode.insertBefore(k,a);
   };

   if (!Functions.isCrawlingMode()) {
      metrica(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      const id = Functions.getYandexID();
      window.ym(id, "init", {
         clickmap:true,
         trackLinks:true,
         accurateTrackBounce:true,
         webvisor:true
      });
   }

   return null;
};

export const LinksToCrawl = ({ links }) => {
   if (Functions.isCrawlingMode()) {
      return (
         <div style={{display: 'none'}}>
            {links.map(link => <Link to={'/' + link + '/'} />)}
         </div>
      );
   }
   else
      return null;
};

export const ExtraColumns = ({ extraColumns, setExtraColumns }) => {
   const { t } = useTranslation();
   const extraColumnsClick = (event) => {
      const checked = event.currentTarget.checked;
      setExtraColumns(checked);
      window.localStorage.extraColumns = Functions.boolForLs(checked);
   };

   return (
      <div className="alignRight mr-3 mb-3">
         <CustomInput type="switch" name="extraColumns" id="extraColumns" label={t('Filters.extraColumns')} className="noSelect" onClick={extraColumnsClick} defaultChecked={extraColumns} />
      </div>
   );
};

export let Filters = ({ bonds, allBonds, calculatedBonds, ndflFreeBonds, dispatch, priceType, realRatePeriod, read, minified, myBonds, children }) => {
   const { t } = useTranslation();
   const [priceRefreshNoticeVisible, setVisiblePriceRefreshNotice] = useState(false);
   // const [filterResultVisible, setVisibleFilterResult] = useState(false);
   if (!Functions.isSet(minified))
      minified = false;

   useEffect(() => {
      fetch('/ndfl.json').then(response => response.json()).then(data => dispatch({ type: 'saveNdflFreeBonds', ndflFreeBonds: data}));
   }, [dispatch]);

   const testCheckbox = (checkbox, value) => {
      if (checkbox.checked)
         return value;
      else
         return false;
   };

   const filterBonds = (calculatedBonds) => {
      const result = calculatedBonds.filter((bond) => {
         let filterPass = false;
         let result;
         
         if (bond.currency !== 'SUR')
            return false;
         
         const cobr = /ru/i;
         if (bond.board === 'TQOB' && cobr.test(bond.code))
            return false;

         const filter = (field, direction) => {
            const bondValue = bond[field];
            if (form[field + direction]) {
               const formValue = form[field + direction].value;
               if (Functions.isSet(formValue) && formValue !== '') {
                  if (direction === 'From')
                     return bondValue >= formValue;
                  if (direction === 'To')
                     return bondValue <= formValue;
               }
            }
            return null;
         };

         const directions = ['From', 'To'];
         const fields = [
            'rate',
            'realRate',
            'revenueByRepaymentPercent',
            'currentPercent',
            'baseDiffInDays',
            'bid',
            'offer',
            'daysTillCoupon',
            'totalBid',
            'totalOffer',
            'bidOfferRatio',
            'spread',
            'tradeCount',
            'totalTradeVolume',
            'lotValue',
            'averagePrice',
            'yearsTillRepayment',
            'nkdPercent'
         ]; 

         for (let i = 0; i < fields.length; i++) {
            for (let j = 0; j < directions.length; j++) {
               result = filter(fields[i], directions[j]);
               if (result !== null) {
                  filterPass = result;
                  if (!filterPass)
                     return false;
               }
            }
         }

         if (form.putOffer.checked) {
            filterPass = bond.putOffer !== true;
            if (!filterPass)
               return false;
         }

         const filterCorp = () => bond.sector === 6;
         const filterExchange = () => bond.sector === 8;
         const filterMunic = () => bond.sector === 4;
         const filterOfz = () => bond.sector === 3 && new RegExp('^SU', 'i').test(bond.code);
         filterPass = testCheckbox(form.exchange, filterExchange()) || testCheckbox(form.corp, filterCorp()) || testCheckbox(form.ofz, filterOfz()) || testCheckbox(form.munic, filterMunic());
         if (!filterPass)
            return false;

         const filterListing = (testValue) => bond.listing === testValue;
         filterPass = testCheckbox(form.level1, filterListing(1)) || testCheckbox(form.level2, filterListing(2)) || testCheckbox(form.level3, filterListing(3));
         if (!filterPass)
            return false;

         // if (form.bonds.value ===  'corp') {
         //    filterPass = bond.sector === 8;
         //    if (!filterPass)
         //       return false;
         // }

         // if (form.bonds.value !== 'all') {
         //    switch (form.bonds.value) {
         //       case 'corp':
         //          filterPass = bond.sector === 8;
         //          break;
         //       case 'ofz':
         //          filterPass = new RegExp('^SU', 'i').test(bond.code); //extract pattern?
         //          break;
         //       case 'munic':
         //          filterPass = bond.sector === 4;
         //          break;
         //    }
         //    if (!filterPass)
         //          return false;
         // }

         const searchValues = form.searchNames.value.split(/\r?\n/);
         if (searchValues && searchValues.length && searchValues[0].trim() !== '') {
            let regs = [];
            searchValues.forEach(value => {
               value = value.trim();
               if (value.length)
                  regs.push(new RegExp(value, 'i'));
            });
            const bondName = Functions.getLocalBondName(bond);
            for (let i = 0; i < regs.length; i++) {
               filterPass = regs[i].test(bondName);
               if (filterPass)
                  break;
            }
            if (!filterPass)
               return false;
         }

         if (myBonds && form.nkdPeriod.value !== 'all') {
            switch (form.nkdPeriod.value) {
               case "sellPeriod":
                  filterPass = bond.currentSellPeriod >= 0;
                  break;
               case "waitPeriod":
                  filterPass = bond.currentWaitPeriod >= 0;
                  break;
            }
            if (!filterPass)
               return false;
         }

         return filterPass;
      });
      dispatch({ type: 'setBonds', bonds: result});
   };

   const applyFilters = (context, event, resolve) => {
      dispatch({ type: 'setTableLoaded', tableLoaded: false });
      const filter = (bonds) => {
         filterBonds(bonds);
         if (updatingPrices)
            setVisiblePriceRefreshNotice(true);
         // else
         //    setVisibleFilterResult(true);
         dispatch({ type: 'setTableLoaded', tableLoaded: true });
         resolve();
         if (!noScroll)
            Functions.scrollTo('scrollPosition', true);
      };
      const setPriceType = () => dispatch({ type: 'setPriceType', priceType: form.priceType.value });
      const setRealRate = () => dispatch({ type: 'setRealRatePeriod', realRatePeriod: form.realRatePeriod.value });

      if (!allBonds.length) {
         setPriceType();
         setRealRate();
         read().then(allBonds => filter(Functions.calculateRelativeTo(allBonds, form.priceType.value, dispatch, form.realRatePeriod.value)));
      }
      else {
         if (priceType !== form.priceType.value || realRatePeriod !== form.realRatePeriod.value) {
            setPriceType();
            setRealRate();
            filter(Functions.calculateRelativeTo(allBonds, form.priceType.value, dispatch, form.realRatePeriod.value));
         }
         else
            filter(calculatedBonds);
      }
   };

   const monitorListing = () => {
      if (!form.level1.checked && !form.level2.checked && !form.level3.checked)
         form.level1.checked = true;
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      monitorListing();
      startFilter();
   };

   const resetForm = () => {
      const priceType = form.priceType.value;
      const realRatePeriod = form.realRatePeriod.value;
      form.reset();
      form.priceType.value = priceType;
      form.realRatePeriod.value = realRatePeriod;
   };

   let updatingPrices;
   const [ startFilter, filterButtonName, filterButtonDisabled ] = useXstateForButton(applyFilters, { ...Functions.actionButtonNames('apply', t), promise: true });
   const [ startRefresh, refreshButtonName, refreshButtonDisabled ] = useXstateForButton((context, event, resolve) => {
      allBonds = [];
      //window.localStorage.removeItem('bondsInfo');
      updatingPrices = true;
      applyFilters(context, event, resolve);
   }, { ...Functions.actionButtonNames('refreshPrices', t), noParams: true, promise: true });
   let noScroll;
   const [ startReset, resetButtonName, resetButtonDisabled ] = useXstateForButton((context, event, resolve) => {
      resetForm();
      noScroll = true;
      applyFilters(context, event, resolve);
   }, { ...Functions.actionButtonNames('resetFilters', t), noParams: true, promise: true });

   const [formRef, form] = Functions.useRef();
   const [extraFiltersVisible, setExtraFiltersVisible] = useState(false);
   const moreFiltersClick = () => setExtraFiltersVisible(!extraFiltersVisible);
   let extraFiltersClass = '';
   let extraFiltersButtonName = t('Buttons.hide');
   if (!extraFiltersVisible) {
      extraFiltersClass = ' hidden';
      extraFiltersButtonName = t('Buttons.show');
   }

   const startFilterCb = useCallback(startFilter, []);
   useEffect(() => {
      if (form) {
         if (minified && minified === true)
            startFilterCb();
      }
   }, [form, minified, startFilterCb]);
   
   let minifiedClass = ' hidden';
   if (minified === true)
      minifiedClass = '';
   const [allFiltersVisible, setAllFiltersVisible] = useState(!minified);
   const allFiltersClick = () =>  {
      setAllFiltersVisible(!allFiltersVisible);
      if (allFiltersVisible)
         startReset();
   };
   let allFiltersButtonName = t('Filters.minified.hideFilters');
   let allFiltersClass = '';
   let formClass = '';
   if (!allFiltersVisible) {
      allFiltersClass = ' allFiltersHidden';
      allFiltersButtonName = t('Filters.minified.filters');
      formClass = 'minifiedForm';
   }

   const buttons = (
      <>
         {bonds.length !== 0 && <Button color="success" outline disabled={refreshButtonDisabled} onClick={startRefresh} className="m-1 inlineBlock">{refreshButtonName}</Button>}
         {children}
      </>
   );

   return (
      <Container fluid className="mb-5">
         <Form inline onSubmit={handleSubmit} innerRef={formRef} className={formClass}>
            <Row className="justify-content-center">
               <Col tag={Button} onClick={allFiltersClick} className={"m-2 p-3 extraFiltersButton" + minifiedClass} color="info">{allFiltersButtonName}</Col>
               <FilterBlock field="rate" addon="%" min="0" step="0.01" t={t} className={allFiltersClass} />
               <FilterBlock field="realRate" addon="%" min="0" step="0.01" t={t} className={allFiltersClass} />
               <FilterBlock field="revenueByRepaymentPercent" addon="%" step="0.01" t={t} className={allFiltersClass} />
               <FilterBlock field="currentPercent" addon="%" min="0.001" max="500" step="0.001" t={t} className={allFiltersClass} />
               {!myBonds && <FilterBlock field="bid" addon="%" min="0.001" step="0.001" max="500" t={t} className={allFiltersClass} />}
               {myBonds && <FilterBlock field="offer" addon="%" min="0.001" step="0.001" max="500" t={t} className={allFiltersClass} />}
               <FilterBlock field="yearsTillRepayment" addon="years" step="0.01" t={t} className={allFiltersClass} />
               <FilterBlock field="nkdPercent" addon="%" min="0" step="0.01" max="100" t={t} className={allFiltersClass} />
               <Col tag={Button} onClick={moreFiltersClick} className={"m-2 p-3 extraFiltersButton" + allFiltersClass} color="secondary">{extraFiltersButtonName} <br /> {t('Filters.extraFilters')}</Col>
               <Block className={"filterNameBlock" + extraFiltersClass + allFiltersClass}>
                  <div className="filterBlockName mb-3">{t('Filters.name.header')}</div>
                  <Input type="textarea" name="searchNames" placeholder={t('Filters.name.placeholder')} />
               </Block>
               <FilterBlock field="baseDiffInDays" addon="days" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="spread" addon="%" min="0" step="0.001" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="totalBid" addon="lots" min="0" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="totalOffer" addon="lots" min="0" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="bidOfferRatio" min="0" step="0.01" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="tradeCount" addon="pieces" min="0" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="totalTradeVolume" addon="lots" min="0" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="lotValue" addon="rub" min="0" step="0.01" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="averagePrice" addon="%" min="0" step="0.001" className={extraFiltersClass + allFiltersClass} t={t} />
               <FilterBlock field="daysTillCoupon" addon="days" step="1" className={extraFiltersClass + allFiltersClass} t={t} />
               {myBonds && <Block className={"filterNdflBlock" + extraFiltersClass + allFiltersClass}>
                  <Btooltip title={t('BondTooltip.nkdPeriod')} enterDelay={Functions.tooltipDelay()}>
                     <div className="filterBlockName mb-3 cursorHelp">{t('Bond.nkdPeriod')}</div>
                  </Btooltip>
                  <CustomInput type="radio" name="nkdPeriod" value="all" id="nkdAll" label={t('Filters.nkdPeriod.all')} className="noSelect" defaultChecked  />
                  <CustomInput type="radio" name="nkdPeriod" value="sellPeriod" id="sellPeriod" label={t('Filters.nkdPeriod.sellPeriod')} className="noSelect"  />
                  <CustomInput type="radio" name="nkdPeriod" value="waitPeriod" id="waitPeriod" label={t('Filters.nkdPeriod.waitPeriod')} className="noSelect" />
               </Block>}
               {!myBonds && <Block className={"filterRealRatePeriodBlock" + allFiltersClass}>
                  <Btooltip title={t('Filters.realRatePeriod.headerTooltip')} enterDelay={Functions.tooltipDelay()}>
                     <div className="filterBlockName mb-3 cursorHelp">{t('Filters.realRatePeriod.header')}</div>
                  </Btooltip>
                  <CustomInput type="radio" name="realRatePeriod" value={1} id="1month" label={t('Filters.realRatePeriod.1month')} className="noSelect" />
                  <CustomInput type="radio" name="realRatePeriod" value={3} id="3months" label={t('Filters.realRatePeriod.3months')} className="noSelect" />
                  <CustomInput type="radio" name="realRatePeriod" value={6} id="6months" label={t('Filters.realRatePeriod.6months')} className="noSelect" />
                  <CustomInput type="radio" name="realRatePeriod" value={12} id="1year" label={t('Filters.realRatePeriod.1year')} className="noSelect" defaultChecked />
                  <CustomInput type="radio" name="realRatePeriod" value={0} id="full" label={t('Filters.realRatePeriod.full')} className="noSelect" />
               </Block>}
               {myBonds && <Input type="hidden" name="realRatePeriod" value={0} />}
               <Block className={"filterLevelBlock" + allFiltersClass}>
                  <div className="filterBlockName mb-3">{t('Bond.listing')}</div>
                  <CustomInput type="checkbox" name="level1" id="level1" label={t('Filters.listing.level1')} className="noSelect" defaultChecked />
                  <CustomInput type="checkbox" name="level2" id="level2" label={t('Filters.listing.level2')} className="noSelect" defaultChecked />
                  <CustomInput type="checkbox" name="level3" id="level3" label={t('Filters.listing.level3')} className="noSelect" defaultChecked={minified} />
               </Block>
               <Block className={"filterSwitchBlock" + allFiltersClass}>
                  <CustomInput type="switch" name="putOffer" id="putOffer" label={t('Filters.putOffer')} className="noSelect" />
               </Block>
               <Block className={"filterNdflBlock" + allFiltersClass}>
                  <div className="filterBlockName mb-3">{t('Filters.type.header')}</div>
                  <CustomInput type="checkbox" name="exchange" id="exchange" label={t('Filters.type.exchange')} className="noSelect" defaultChecked />
                  <CustomInput type="checkbox" name="ofz" id="ofz" label={t('Filters.type.ofz')} className="noSelect" defaultChecked />
                  <CustomInput type="checkbox" name="munic" id="munic" label={t('Filters.type.munic')} className="noSelect" defaultChecked />
                  <CustomInput type="checkbox" name="corp" id="corp" label={t('Filters.type.corp')} className="noSelect" defaultChecked={minified} />
               </Block>
               <Block className="filterCalculateBlock">
                  <div className="filterBlockName mb-3">{t('Filters.calcsRelativeTo.header')}</div>
                  <CustomInput type="radio" name="priceType" value="lastPrice" id="lastPrice" label={t('Filters.calcsRelativeTo.lastPrice')} className="noSelect" defaultChecked />
                  {!myBonds && <CustomInput type="radio" name="priceType" value="bid" id="bid" label={t('Filters.calcsRelativeTo.bid')} className="noSelect" />}
                  {myBonds && <CustomInput type="radio" name="priceType" value="offer" id="offer" label={t('Filters.calcsRelativeTo.offer')} className="noSelect" />}
                  <div className="alignRight mt-3">
                     <Button color="info" outline disabled={filterButtonDisabled} id="applyFilters">{filterButtonName}</Button>
                  </div>
               </Block>
            </Row>
         </Form>
         {allFiltersVisible === true && 
            <div className={"pt-1 alignRight"} id="scrollPosition">
               {buttons}
               <Button color="warning" outline onClick={startReset} disabled={resetButtonDisabled} className="m-1 inlineBlock">{resetButtonName}</Button>
               <span className="greyColor ml-4 noWrap">{t('Filters.found')}: {bonds.length}</span>
            </div>
         }
         {allFiltersVisible === false && 
            <span className="floatRight">
               {buttons}
            </span>
         }
         <div className="clear"></div>
         <AddPriceRefreshNotice visible={priceRefreshNoticeVisible} setVisible={setVisiblePriceRefreshNotice} />
         {/* <FilterResultNotice visible={filterResultVisible} setVisible={setVisibleFilterResult} count={bonds.length} /> */}
      </Container>
   );
};
Filters = connect(state => state)(Filters);

const Block = ({ children, className }) =>  {
   let classValue = 'm-2 p-3 bg-white rounded shadow-sm';
   if (className)
      classValue += ' ' + className;
   
   return <Col className={classValue}>{children}</Col>
};

const FilterBlock = ({ field, addon, min, max, step, className, t }) => {
   let postfix;
   if (addon) {
      if (addon !== '%')
         addon = t('Endings.' + addon);
      postfix = <InputGroupAddon addonType="append">{addon}</InputGroupAddon>;
   }
   const bondTitle = Functions.getBondTitle({ table: true, t });
   const tooltips = Functions.getBondTooltips(t);
   const tooltip = tooltips[field];
   if (!className)
      className = '';
   
   const group = (label, inputName) => (
      <FormGroup className="mb-3 justify-content-center">
         <Label className="mr-sm-2">{label}</Label>
         <InputGroup>
            <Input type="number" name={inputName} min={min} max={max} step={step} className="filterBlockSm" autoComplete="off" />{postfix}
         </InputGroup>
      </FormGroup>
   );

   const headerDiv = <div className="filterBlockName mb-3 alignCenter">{bondTitle[field]}</div>;
   let header;
   if (Functions.isSet(tooltip))
      header = <Btooltip title={tooltip} className="cursorHelp" enterDelay={Functions.tooltipDelay()}>{headerDiv}</Btooltip>;
   else
      header = headerDiv;

   return (
      <Block className={"filterBlock" + className}>
         {header}
         {group(t('Filters.range.from'), field + 'From')}
         {group(t('Filters.range.to'), field + 'To')}
      </Block>
   );
};

// export const FilteredListButton = ({ data }) => {
//    const click = () => Functions.writeFilterData(data);
//    return (
//       <div className="alignCenter mt-5">
//          <Button tag={Link} size="lg" to="/findBonds/" color="info" onClick={click}>Перейти к списку</Button>
//       </div>
//    );
// };

export const MyFees = () => {
   window.setMyFees = () => {
      const fees = {
         fee: 0.057,
         minFee: 0.04
     };
     Functions.setToLs(fees, 'fees');
   };
   window.removeMyFees = () => window.localStorage.removeItem('fees');

   return null;
};

export const FeeDialog = () => {
   const { t } = useTranslation();
   let fees = Functions.getFees();
   const [open, setOpen] = useState(false);
   const [buttonVisible, setButtonVisible] = useState(!Functions.isSet(fees));
   const [invalidFee, setInvalidFee] = useState(false);
   const toggleDialog = () => setOpen(!open);
   const onClosed = () => setInvalidFee(false);
   const dialogProps = { header: t('FeeDialog.header'), open, size: 'sm', toggleDialog, onClosed };
   
   if (fees) {
      if (buttonVisible)
         setButtonVisible(false);
   }
   else {
      fees = {};
      if (!buttonVisible)
         setButtonVisible(true);
   }
   const { fee, minFee } = fees;
   const [feeInputRef, feeInput] = Functions.useRef(node => {
      if (Functions.isSet(fee))
         node.value = fee;
   }, [fee]);
   const [minFeeInputRef, minFeeInput] = Functions.useRef(node => {
      if (Functions.isSet(minFee))
         node.value = minFee;
   }, [fee]);
   const [minFeeSwitchRef, minFeeSwitch] = Functions.useRef();

   const saveFees = (event) => {
      event.preventDefault();
      let newFee = feeInput.value;
      if (newFee) {
         setInvalidFee(false);
         newFee = parseFloat(newFee);
         if (newFee > 0) {
            const fees = {};
            fees.fee = newFee;
            let newMinFee = minFeeInput.value;
            if (minFeeVisible && Functions.isSet(newMinFee)) {
               newMinFee = parseFloat(newMinFee);
               if (newMinFee > 0)
                  fees.minFee = newMinFee;
            }
            Functions.setFees(fees);
            Functions.setYandexGoal('setFees');
            toggleDialog();
            window.location.reload();
         }
      }
      else
         setInvalidFee(true);
   };

   const deleteFees = () => {
      window.localStorage.removeItem('fees');
      setMinFeeVisible(false);
      toggleDialog();
      window.location.reload();
   };

   const [minFeeVisible, setMinFeeVisible] = useState(Functions.isSet(minFee));
   const minFeeClick = () => {
      setMinFeeVisible(minFeeSwitch.checked);
      if (!minFeeSwitch.checked)
         minFeeInput.value = '';
   };

   return (
      <span>
         {buttonVisible && <Btooltip title={t('FeeDialog.feeButtonTitle')} enterDelay={Functions.tooltipDelay()}><Button color="warning" onClick={toggleDialog} data-test-id="feeButton">{t('FeeDialog.feeButton')}</Button></Btooltip>}
         {!buttonVisible && <Btooltip title={t('FeeDialog.feeLinkTitle')} enterDelay={Functions.tooltipDelay()}><span onClick={toggleDialog} className="feeSet nav-link" data-test-id="feeLink">{t('FeeDialog.feeLink')}</span></Btooltip>}
         <Dialog { ...dialogProps }>
            <div className="mb-3">
               <div className="alignCenter">
                  <Button color="info" id="dialogWhyCollapse">{t('Buttons.whyIsThat')}</Button>
               </div>
               <UncontrolledCollapse toggler="#dialogWhyCollapse" className="mt-3">{t('FeeDialog.text')}</UncontrolledCollapse>
            </div>
            <Form onSubmit={saveFees} name="fees">
               <FormGroup className="mb-3 justify-content-center">
                  <Btooltip title={t('FeeDialog.feeTitle')} enterDelay={Functions.tooltipDelay()}>
                     <span><Label className="mr-sm-2 cursorHelp">{t('FeeDialog.fee')}</Label></span>
                  </Btooltip>
                  <InputGroup>
                     <Input type="number" name="fee" invalid={invalidFee} min={0.00001} max={0.9} step={0.00001} className="alignCenter" autoComplete="off" innerRef={feeInputRef} />
                     <InputGroupAddon addonType="append">%</InputGroupAddon>
                     <FormFeedback valid={!invalidFee}>{t('BondDialog.errors.cannotBeEmpty')}</FormFeedback>
                  </InputGroup>
               </FormGroup>
               <FormGroup className="mb-3 justify-content-center">
                  <CustomInput type="switch" name="minFeeSwitch" id="minFeeSwitch" label={t('FeeDialog.minFee')} className="noSelect minFeeSwitch" onClick={minFeeClick} innerRef={minFeeSwitchRef} defaultChecked={minFeeVisible} />
                  <Collapse isOpen={minFeeVisible}>
                     <InputGroup>
                        <Input type="number" name="minFee" min={0.00001} step={0.00001} className="alignCenter" autoComplete="off" innerRef={minFeeInputRef} />
                        <InputGroupAddon addonType="append">{t('Endings.rub')}</InputGroupAddon>
                     </InputGroup>
                  </Collapse>
               </FormGroup>
               <div className="alignRight mt-4">
                  <Button color="danger" outline name="delete" onClick={deleteFees}>{t('Buttons.delete')}</Button>
                  <Button color="info" outline name="save" className="ml-2">{t('Buttons.save.start')}</Button>
               </div>
            </Form>
         </Dialog>
      </span>
   );
};

export const FeedbackForm = ({ test }) => {
   const { t } = useTranslation();
   const [formRef, form] = Functions.useRef();
   const [invalid, setInvalid] = useState(false);
   const [captchaRef, captcha] = Functions.useRef();

   let sitekey;
   let actionUrl;
   if (test) {
      sitekey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
      actionUrl = '/testSendMail.php';
   }
   else {
      sitekey = '6LfI7NMZAAAAAGwQf6i6aiLMxsKczcdiWaKcK9fy';
      actionUrl = '/sendMail.php';
   }

   const handleSubmit = (event) => {
       event.preventDefault();
       if (!form.message.value.length) {
           setInvalid(true);
           return;
       }
       else
           setInvalid(false);
       
       const data = {
           message: form.message.value,
           email: form.email.value
       };
       if (captcha)
           data.captcha = captcha.getValue();
       startSubmit(data);
   };

   const [ startSubmit, submitButtonName, submitButtonDisabled ] = useXstateForButton(async (context, event) => {
       const data = event;
       const url = window.location.origin + actionUrl;
       const errorAlert = () => {
         alert(t('Feedback.alert.error'));
         captcha.reset();
       };

       return fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data)
       }).then(response => response.json()).then(json => {
           switch (json.message) {
               case "ok":
                   alert(t('Feedback.alert.success'));
                   form.reset();
                   captcha.reset();
                   if (test) {
                      form.messageTest.value = json.text;
                      form.emailTest.value = json.email;
                   }
                   break;
               case "error":
                   errorAlert();
                   break;
               case "notValid":
                   alert(t('Feedback.alert.captcha'));
                   break;
               default:
                  throw new Error('Unexpected response code');
           }
       }).catch(error => {
            errorAlert();
       });
   }, Functions.actionButtonNames('send', t));

   return (
       <Row>
           <Col lg="8" className="mx-auto">
               <Form name="feedback" onSubmit={handleSubmit} innerRef={formRef}>
                   <FormGroup>
                       <Label>{t('Feedback.labels.message')}</Label>
                       <Input name="message" className="feedbackMessage" invalid={invalid} type="textarea" autoComplete="off" />
                       <FormFeedback valid={!invalid}>{t('Feedback.hints.message')}</FormFeedback>
                   </FormGroup>
                   <FormGroup>
                       <Label>{t('Feedback.labels.email')}</Label>
                       <Input name="email" type="email" autoComplete="off" placeholder={t('Feedback.hints.email')} />
                   </FormGroup>
                   <div className="alignCenter mt-5 mb-4">
                       <div className="inlineBlock">
                           <ReCAPTCHA
                               sitekey={sitekey}
                               ref={captchaRef}
                               hl={Functions.getCurrentLanguage()}
                               theme="light"
                           />
                       </div>
                   </div>
                   {test && <Input type="hidden" name="messageTest" />}
                   {test && <Input type="hidden" name="emailTest" />}
                   <FormGroup className="alignRight">
                       <Button name="send" color="info" size="lg" outline disabled={submitButtonDisabled}>{submitButtonName}</Button>
                   </FormGroup>
               </Form>
           </Col>
       </Row>
   );
};

export const Btooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: 13
    }
}))(Tooltip);

export const DoubleChart = ({ bond }) => {
   const dayCharRef = React.useRef();
   const minuteChartRef = React.useRef();
   const [date, setDate] = useState(() => Functions.composeMySqlDate(new Date()));

   useEffect(() => {
      const candleClick = (param) => {
         if (Functions.isSet(param) && Functions.isSet(param.time)) {
            const time = param.time;
            const date = time.year + "-" + time.month + "-" + time.day;
            setDate(date);
         }
      };
      dayCharRef.current.subscribeClick(candleClick);
      
      return () => dayCharRef.current.unsubscribeClick(candleClick);
   }, []);

   return (
      <div className="alignCenter">
         <Chart chartRef={dayCharRef} period={24} code={bond.code} purchaseDate={bond.purchaseDate} purchasePrice={bond.purchasePercent} />
         <Chart chartRef={minuteChartRef} period={1} code={bond.code} purchaseDate={bond.purchaseDate} purchasePrice={bond.purchasePercent} date={date} />
      </div>
   );
};

const Chart = ({ chartRef, period, code, purchasePrice, purchaseDate, date }) => {
   const ref = React.useRef();
   const candleSeriesRef = React.useRef();
   const volumeSeriesRef = React.useRef();
   const [tooltipVisible, setTooltipVisible] = useState(false);
   const [tooltipPosition, setTooltipPosition] = useState({});
   const [tooltipData, setTooltipData] = useState({});
   const [chartWidth, setChartWidth] = useState();
   const { t } = useTranslation();

   const chartHeight = 300;
   let timeVisible = false;
   let rightOffset = 10;
   let priceLineVisible = true;
   let chartHint = t('BondCard.chartTooltipHint');
   if (period === 1) {
      timeVisible = true;
      rightOffset = 0;
      priceLineVisible = false;
      chartHint = t('BondCard.minuteChartHint');
   }

   useEffect(() => {
      const chart = createChart(ref.current, {
         localization: {
            locale: Functions.getCurrentLanguage()
         },
         timeScale: {
            borderColor: '#D1D4DC',
            timeVisible: timeVisible,
            secondsVisible: false,
            rightOffset: rightOffset
         },
         rightPriceScale: {
            borderColor: '#D1D4DC',
            scaleMargins: {
               top: 0.1,
               bottom: 0.3
            }
         },
         layout: {
            backgroundColor: '#ffffff',
            textColor: '#000'
         },
         grid: {
            horzLines: {
               color: '#F0F3FA'
            },
            vertLines: {
               color: '#F0F3FA'
            }
         }
      });
      chartRef.current = chart;

      const candleSeries = chart.addCandlestickSeries({
         upColor: 'rgb(38,166,154)',
         downColor: 'rgb(255,82,82)',
         wickUpColor: 'rgb(38,166,154)',
         wickDownColor: 'rgb(255,82,82)',
         borderVisible: false,
         priceLineVisible: priceLineVisible
      });
      candleSeriesRef.current = candleSeries;
   
      const volumeSeries = chart.addHistogramSeries({
         color: '#06b7d2',
         priceFormat: {
            type: 'volume',
         },
         priceScaleId: '',
         scaleMargins: {
            top: 0.8,
            bottom: 0
         },
         lastValueVisible: false,
         priceLineVisible: false
      });
      volumeSeriesRef.current = volumeSeries;

      const allowPurchaseMarker = (firstDate) => {
         if (!Functions.isSet(firstDate) || !Functions.isSet(purchaseDate))
            return false;

         const firstDateUnix = new Date(firstDate.year, firstDate.month, firstDate.day).getTime();
         const purchaseDateUnix = new Date(purchaseDate).getTime();
         if (purchaseDateUnix >= firstDateUnix)
            return true;
         else
            return false;
      };

      if (period === 24) {
         Functions.fetchDayCandles(code).then(({ candles, volume }) => {
            candleSeries.setData(candles);
            volumeSeries.setData(volume);
            
            if (Functions.isSet(candles[0]) && allowPurchaseMarker(candles[0].time)) {
               const markers = [];
               markers.push({ time: purchaseDate, position: 'aboveBar', color: '#06b7d2', shape: 'arrowDown', text: 'Покупка' });
               candleSeries.setMarkers(markers);
            }
         });
         if (Functions.isSet(purchasePrice)) {
            candleSeries.createPriceLine({
               price: purchasePrice,
               color: '#06b7d2',
               lineWidth: 1,
               axisLabelVisible: true
            });
         }
      }
   
      if (period === 1) {
         Functions.fetchMinuteCandles(code, date).then(({ candles, volume }) => {
            candleSeries.setData(candles);
            volumeSeries.setData(volume);
            chart.timeScale().fitContent();
         });
      }
   
      const moveTooltip = (param) => {
         if (!param.time)
            setTooltipVisible(false);
         else
            setTooltipVisible(true);
            
         const candle = param.seriesPrices.get(candleSeries);
         let volume = param.seriesPrices.get(volumeSeries);
         if (Functions.isSet(volume))
            volume = Functions.triad(Math.round(volume));
         else
            volume = 0;
         if (Functions.isSet(candle)) {
            setTooltipData({
               low: candle.low,
               high: candle.high,
               volume: volume
            });
         }
         
         if (Functions.isSet(param.point)) {
            const toolTipWidth = 130;
            const toolTipHeight = 55;
            const toolTipMargin = 15;
            var left = param.point.x + toolTipMargin;
            if (left > chartWidth - toolTipWidth) // chartWidth пока undefined
               left = param.point.x - toolTipMargin - toolTipWidth;
            var y = param.point.y;
            var top = y + toolTipMargin;
            if (top > chartHeight - toolTipHeight)
               top = y - toolTipHeight - toolTipMargin;
            setTooltipPosition({left, top});
         }
      };
      chart.subscribeCrosshairMove(moveTooltip);
   
      const resizeChart = () => {
         const screenWidth = window.innerWidth || document.documentElement.clientWidth;
         let xOffset;
         let newWidth;
         let fixWidth = false;
         if (screenWidth < 1000)
            xOffset = 110;
         else {
            xOffset = 550;
            if (period === 1)
               fixWidth = true;
         }
         if (fixWidth)
            newWidth = 400;
         else
            newWidth = screenWidth - xOffset;
         chart.resize(newWidth, chartHeight);
         setChartWidth(newWidth);
      };
      resizeChart();
      window.addEventListener('resize', resizeChart);

      return () => {
         window.removeEventListener('resize', resizeChart);
         chart.unsubscribeCrosshairMove(moveTooltip);
      };
   }, []);

   useEffect(() => {
      if (period === 1) {
         Functions.fetchMinuteCandles(code, date).then(({ candles, volume }) => {
            candleSeriesRef.current.setData(candles);
            volumeSeriesRef.current.setData(volume);
            chartRef.current.timeScale().fitContent();
         });
      }
   }, [date]);

   let tooltipClass;
   if (tooltipVisible)
      tooltipClass = '';
   else
      tooltipClass = 'hidden';

   return (
      <div className="positionRelative inlineBlock bg-white rounded shadow-sm m-3">
         {period === 1 && <div className="intradayChartDate">{Functions.formatDate(date)}</div>}
         <div className="inlineBlock " ref={ref} />
         <div className={"chartTooltip " + tooltipClass} style={tooltipPosition}>
            <div className="chartTooltipPrices">{tooltipData.low + " - " + tooltipData.high}</div>
            <div className="chartTooltipVolume">{tooltipData.volume}</div>
         </div>
         <div className="chartTooltipHint mt-2 mb-1">{chartHint}</div>
      </div>
   );
};

const CardBlock = memo(({ children }) => {
   const { t } = useTranslation();
   return (
       <Col lg="5" md="6" sm="9">
           <div className="my-3 p-3 bg-white rounded shadow-sm">
               <STable className="bondInfoTable">
                   <tbody>
                       {children.map((item, index) => {
                           const field = item[0];
                           const value = item[1];
                           const isBold = item[2];
                           const isNdfl = item[3];
                           const fieldTooltip = item[4];
                           let valueTitle = item[5];

                           let rowClass = '';
                           let valueColumn;
                           let fieldColumn;
                           if (isNdfl)
                              valueTitle = t('BondTooltip.withTax');
                           if (Functions.isSet(valueTitle)) {
                              valueColumn = (
                                 <td>
                                    <Btooltip enterDelay={Functions.tooltipDelay()} title={valueTitle}>
                                       <span className="ndfl cursorHelp">{value}</span>
                                    </Btooltip>
                                 </td>
                              );
                           }
                           else
                              valueColumn = <td>{value}</td>;
                           if (isBold)
                               rowClass += ' bold';
                           if (Functions.isSet(fieldTooltip)) {
                              fieldColumn = (
                                 <td>
                                    <Btooltip enterDelay={Functions.tooltipDelay()} title={fieldTooltip}>
                                          <span className="cursorHelp">{field}</span>
                                    </Btooltip>
                                 </td>
                              );
                           }
                           else
                              fieldColumn = <td>{field}</td>;

                           return (
                              <tr className={rowClass} key={index}>
                                 {fieldColumn}
                                 {valueColumn}
                              </tr>
                           );
                       })}
                   </tbody>
               </STable>
           </div>
       </Col>
   );
});

const TaxTooltip = memo(({ children }) => {
   const { t } = useTranslation();
   const title = t('BondTooltip.withTax');
   return <Btooltip title={title} enterDelay={Functions.tooltipDelay()}>{children}</Btooltip>;
});

export const MyBondCard = ({ 
      bond,
      add,
      isRedeemed,
      notRedeemed,
      taxSplitMode,
      putNotRedeemed,
      bold,
      ndfl
   }) => {
      return (
         <Row className="justify-content-center">
            <CardBlock>{[
               add('rate'),
               add('realRate', { ndfl }),
               add('currentYearRevenuePercent', { ndfl }),
               add('remainRepaymentRate', { ndfl }),
               add('revenueByRepaymentPercent', { ndfl }),
               add('currentRevenuePercent', { ndfl }),
               add('guaranteedRevenueByRepaymentPercent', { ndfl })
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result = [
                  add('purchasePercent'),
                  add('amount'),
                  add('purchaseCostForAmount'),
                  add('currentCostForAmount'),
                  add('purchaseCostWithNkd'),
               ];
               let element;
               if (bond.priceType === 'offer')
                     element = add('offer');
               else
                     element = add('currentPercent')
               result.splice(1, 0, element);
               return result;
            })()}</CardBlock>
            <CardBlock>{[
               add('daysOwn'),
               add('calculatedDaysOwn'),
               add('calculatedDaysOwnDiff'),
               add('guaranteedRevenueDiffInDays'),
               add('daysTillRepayment')
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result = [
                  add('buyNkd'),
                  add('nkdForAmount'),
                  add('nkdPeriod')
               ];
               if (bond.nkdDeltaInDays >= 0) {
                  result = result.concat([
                     add('currentSellPeriod')
                  ]);
               }
               else {
                  result = result.concat([
                     add('currentWaitPeriod')
                  ]);
               }
               return result;
            })()}</CardBlock>
            {notRedeemed && <CardBlock>{(() => {
               let result = [
                  add('currentRevenue', { bold, ndfl }),
                  add('tradeRevenue', { ndfl })
               ];
               if (taxSplitMode) {
                  result = result.concat([
                     add('couponsBefore'),
                     add('couponsAfter', { ndfl })
                  ]);
               }
               else {
                  result = result.concat([
                     add('coupons', { ndfl })
                  ]);
               }
               return result;
            })()}</CardBlock>}
            {notRedeemed && <CardBlock>{(() => {
               let result = [
                  add('guaranteedRevenueByRepayment', { bold, ndfl }),
                  add('guaranteedTradeRevenue', { ndfl })
               ];
               if (taxSplitMode) {
                  result = result.concat([
                     add('allCouponsBefore'),
                     add('allCouponsAfter', { ndfl })
                  ]);
               }
               else {
                  result = result.concat([
                     add('allCoupons', { ndfl })    
                  ]);
               }
               return result;
            })()}</CardBlock>}
            {notRedeemed && <CardBlock>{(() => {
               let result = [
                  add('nob', { bold }),
                  add('costDiff'),
                  add('nkdDelta')
               ];
               if (Functions.getFees()) {
                  result = result.concat([
                     add('feeForPurchase'),
                     add('feeForSale')
                  ]);
               }
               return result;
            })()}</CardBlock>}
            {notRedeemed && <CardBlock>{(() => {
               let result = [
                  add('repaymentNob', { bold }),
                  add('baseCostDiff'),
                  add('buyNkdWithDays')
               ];
               if (Functions.getFees()) {
                  result = result.concat([
                     add('feeForPurchase')
                  ]);
               }
               return result;
            })()}</CardBlock>}
            <CardBlock>{[
               add('guaranteedRevenueDiff')
            ]}</CardBlock>
            {notRedeemed && <CardBlock>{[
               add('dayRevenueForAmount', { ndfl }),
               add('monthRevenueForAmount', { ndfl }),
               add('yearRevenueForAmount', { ndfl })
            ]}</CardBlock>}
            <CardBlock>{[
               add('zeroOnePercentChangeInDays'),
               add('onePercentChangeInDays')
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result = [
                  add('purchaseDate'),
               ];
               if (putNotRedeemed) {
                  return result.concat([
                     add('buyBackDate'),
                     add('repaymentDate'),
                     add('daysTillRedemption'),
                     add('buyBackPrice')
                  ]);
               }
               else
                  return result.concat([add('repaymentDate')]);
            })()}</CardBlock>
            {notRedeemed && <CardBlock>{(() => {
               let result = [
                  add('couponPeriod'),
                  add('couponDate'),
                  add('daysTillCoupon'),
               ];
               if (taxSplitMode) {
                  result = result.concat([
                     add('couponPaymentBefore'),
                     add('couponPaymentAfter', { ndfl }),
                     add('couponPaymentCountBefore'),
                     add('couponPaymentCountAfter')
                  ]);
               }
               else {
                  result = result.concat([
                     add('couponPayment', { ndfl }),
                     add('couponPaymentCount')
                  ]);
               }
               return result;
            })()}
            </CardBlock>}
            <CardBlock>{[
               add('currentPercent'),
               add('bid'),
               add('offer'),
               add('averagePrice'),
               add('lowHighPrice')
            ]}</CardBlock>
            <CardBlock>{[
               add('totalBid'),
               add('totalOffer'),
               add('bidOfferRatio'),
               add('tradeCount'),
               add('totalTradeVolume'),
               add('lastTradeVolume'),
               add('lastTradeTime'),
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result;
               if (Functions.isRus()) {
                  result = [
                     add('fullName'),
                     add('name'),
                     add('engName'),
                  ];
               }
               else {
                  result = [
                     add('engName'),
                     add('fullName'),
                     add('name')
                  ];
               }
               return result.concat([
                  add('code'),
                  add('isin'),
                  add('sector'),
                  add('listing'),
                  add('lotValue'),
                  add('nkd')
               ]);
            })()}</CardBlock>
         </Row>
      );
};

export const NewBondCard = ({ 
      bond,
      add,
      isRedeemed,
      notRedeemed,
      taxSplitMode,
      putNotRedeemed,
      bold,
      ndfl
   }) => {
      return (
         <Row className="justify-content-center">
            <CardBlock>{[
               add('rate'),
               add('realRate', { ndfl }),
               add('rateToBaseDiffInDays'),
               add('revenueByRepaymentPercent', { ndfl })
            ]}</CardBlock>
            <CardBlock>{[
               add('currentPercent'),
               add('bid'),
               add('averagePrice'),
               add('lowHighPrice'),
               add('baseDiffInDays'),
            ]}</CardBlock>
            {notRedeemed && <CardBlock>{[
               add('nkdPercent'),
               add('nkdPeriod'),
               add('couponPeriod'),
               add('couponDate'),
               add('daysTillCoupon'),
            ]}</CardBlock>}
            <CardBlock>{[
               add('totalBid'),
               add('totalOffer'),
               add('bidOfferRatio'),
               add('tradeCount'),
               add('totalTradeVolume'),
               add('lastTradeVolume'),
               add('lastTradeTime'),
            ]}</CardBlock>
            <CardBlock>{[
               add('listing'),
               add('lotValue'),
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result = [];
               if (putNotRedeemed) {
                  result.push(add('buyBackDate'));
                  result.push(add('daysTillRepayment'));
               }
               else {
                  result.push(add('repaymentDate'));
                  result.push(add('daysTillRepayment'));
               }
               if (putNotRedeemed) {
                  result = result.concat([
                     add('repaymentDate'),
                     add('daysTillRedemption'),
                     add('buyBackPrice')
                  ]);
               }
            return result;
            })()}</CardBlock>
            <CardBlock>{[
               add('offer'),
               add('spread')
            ]}</CardBlock>
            <CardBlock>{(() => {
               let result;
               if (Functions.isRus()) {
                  result = [
                     add('fullName'),
                     add('name'),
                     add('engName'),
                  ];
               }
               else {
                  result = [
                     add('engName'),
                     add('fullName'),
                     add('name')
                  ];
               }
               return result.concat([
                  add('code'),
                  add('isin'),
                  add('sector'),
                  add('nkd')
               ]);
            })()}</CardBlock>
         </Row>
      );
};

export const CalcBondCard = ({ 
   bond,
   add,
   isRedeemed,
   notRedeemed,
   taxSplitMode,
   putNotRedeemed,
   bold,
   ndfl
}) => {
   return (
      <Row className="justify-content-center">
         <CardBlock>{[
            add('rate'),
            add('currentYieldNN'),
            add('currentYieldModifiedNN'),
            add('simpleYieldNN'),
            add('ytm'),
            add('nomYield')
         ]}</CardBlock>
         <CardBlock>{[
            add('realRate', { ndfl }),
            add('currentYield', { ndfl }),
            add('currentYieldModified', { ndfl }),
            add('simpleYield', { ndfl })
         ]}</CardBlock>
         <CardBlock>{[
            add('duration'),
            add('durationModified'),
            add('pvbp')
         ]}</CardBlock>
         <CardBlock>{[
            add('currentPercent'),
            add('bid'),
            add('offer'),
            add('averagePrice'),
            add('spread'),
            add('lowHighPrice'),
            add('baseDiffInDays'),
         ]}</CardBlock>
         <CardBlock>{(() => {
            let result = [];
            if (notRedeemed) {
               result = [
                  add('nkdPercent'),
                  add('nkdPeriod'),
                  add('couponPeriod'),
                  add('couponDate'),
                  add('daysTillCoupon')
               ];   
            }
            if (putNotRedeemed) {
               result.push(add('buyBackDate'));
               result.push(add('daysTillRepayment'));
            }
            else {
               result.push(add('repaymentDate'));
               result.push(add('daysTillRepayment'));
            }
            if (putNotRedeemed) {
               result = result.concat([
                  add('repaymentDate'),
                  add('daysTillRedemption'),
                  add('buyBackPrice')
               ]);
            }
         return result;
         })()}</CardBlock>
         <CardBlock>{[
            add('totalBid'),
            add('totalOffer'),
            add('bidOfferRatio'),
            add('tradeCount'),
            add('totalTradeVolume'),
            add('lastTradeVolume'),
            add('lastTradeTime'),
         ]}</CardBlock>
         <CardBlock>{[
            add('listing'),
            add('lotValue'),
            add('nkd')
         ]}</CardBlock>
         <CardBlock>{(() => {
            let result;
            if (Functions.isRus()) {
               result = [
                  add('fullName'),
                  add('name'),
                  add('engName'),
               ];
            }
            else {
               result = [
                  add('engName'),
                  add('fullName'),
                  add('name')
               ];
            }
            return result.concat([
               add('code'),
               add('isin'),
               add('sector')
            ]);
         })()}</CardBlock>
      </Row>
   );
};

export const BondCodeForm = ({ processSubmit, invalid, setInvalid, button }) => {
   const { t } = useTranslation();
   const [codeRef, codeInput] = Functions.useRef();

   const [start, buttonName, buttonDisabled] = useXstateForButton(async (context, event) => {
      let { code } = event;
      code = code.toUpperCase();
      return processSubmit(code);
   }, Functions.actionButtonNames(button, t));

   const handleSubmit = (event) => {
      event.preventDefault();
      if (codeInput.value.length > 0) {
         const code = codeInput.value;
         start({ code: code });
      }
      else {
         setInvalid({
            status: true,
            message: t('BondDialog.errors.cannotBeEmpty')
         });
      }
   };

   let tabIndex = 1;
   return (
      <Form onSubmit={handleSubmit}>
         <FormGroup row>
            <Col>
               <Input invalid={invalid.status} type="text" name="code" innerRef={codeRef} autoComplete="off" placeholder={t('BondDialog.labels.code')} tabIndex={tabIndex++} />
               <FormFeedback valid={!invalid.status}>{invalid.message}</FormFeedback>
               <FormText>{t('BondDialog.hints.inputCode.newBonds')} (<BondCodeLink t={t} />)</FormText>
            </Col>
            <Col sm="auto" className="alignRight"> 
               <Button name="save" disabled={buttonDisabled} color="info" tabIndex={tabIndex++}>{buttonName}</Button>
            </Col>
         </FormGroup>
      </Form>
   );
};