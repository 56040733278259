import React from 'react';
import { Header } from './modules';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Functions } from './functions';

export const AboutCode = () => {
    const { t } = useTranslation();
    const codeImg = 'code-' + Functions.getCurrentLanguage();

    return (
        <div>
            <Header>{t('AboutCode.header')}</Header>
            <Container>
                <Row>
                    <Col lg="4">
                        <div className="mb-4 mr-2">
                            <p>
                                {t('AboutCode.text.part1')}
                            </p>
                            <p>
                                {t('AboutCode.text.part2')}
                            </p>
                        </div>
                    </Col>
                    <Col className="alignCenter">
                        <div className="inlineBlock">
                            <img src={"/" + codeImg + ".png"} />
                            <div className="imageCaption mt-1">{t('AboutCode.caption')}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};