export const bonds = (state = [], action) => {
    switch (action.type) {
        case 'setBonds':
            return action.bonds;
        case 'addBond':
            return addBond(state, action);
        case 'updateBond':
            return updateBonds(state, action);
        case 'deleteBond':
            return deleteBond(state, action);
        default:
            return state;
    }
};

export const allBonds = (state = [], action) => {
    switch (action.type) {
        case 'saveAllBonds':
            return action.allBonds;
        case 'addToAllBonds':
            return addBond(state, action);
        case 'updateAllBonds':
            return updateBonds(state, action);
        case 'deleteFromAllBonds':
            return deleteBond(state, action);
        default:
            return state;
    }
};

export const calculatedBonds = (state = [], action) => {
    switch (action.type) {
        case 'saveCalculatedBonds':
            return action.calculatedBonds;
        case 'addToCalculatedBonds':
            return addBond(state, action);
        case 'updateCalculatedBonds':
            return updateBonds(state, action);
        case 'deleteFromCalculatedBonds':
            return deleteBond(state, action);
        default:
            return state;
    }
};

export const currentSorting = (state = '', action) => {
    switch (action.type) {
        case 'applySorting':
            return action.sorting;
        default:
            return state;
    }
};

export const ndflFreeBonds = (state = [], action) => {
    switch (action.type) {
        case 'saveNdflFreeBonds':
            return action.ndflFreeBonds;
        default:
            return state;
    }
};

export const tableLoaded = (state = true, action) => {
    switch (action.type) {
        case 'setTableLoaded':
            return action.tableLoaded;
        default:
            return state;
    }
};

export const priceType = (state = '', action) => {
    switch (action.type) {
        case 'setPriceType':
            return action.priceType;
        default:
            return state;
    }
};

export const realRatePeriod = (state = '', action) => {
    switch (action.type) {
        case 'setRealRatePeriod':
            return action.realRatePeriod;
        default:
            return state;
    }
};

const deleteBond = (state, action) => {
    const prop = action.prop;
    return state.filter(bond => bond[prop] !== action[prop]);
};

const addBond = (state, action) => [ ...state, action.bond ];

const updateBonds = (state, action) => {
    return state.map(bond => {
        if (bond.id === action.bond.id)
            return action.bond;
        else
            return bond;
    });
};