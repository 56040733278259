import React, { memo } from 'react';
import { Container, Alert } from 'reactstrap';
import { Functions } from './functions';
import { useTranslation } from 'react-i18next';
import { MoexButton, MyBondCard, NewBondCard, CalcBondCard, DoubleChart } from './modules'; 


export const BondCard = memo(({ bond, type }) => {
    const { t } = useTranslation();
    const displayBond = Functions.prepareDisplayData(bond, t, true);
    const isRedeemed = displayBond.extra.isRedeemed;
    const notRedeemed = !isRedeemed;
    const taxSplitMode = bond.taxYearSplit;
    const putOffer = displayBond.extra.putOffer;
    const putNotRedeemed = putOffer && notRedeemed;
    const bondTitle = Functions.getBondTitle({ put: putOffer, redeemed: isRedeemed, t });
    const tooltips = Functions.getBondTooltips(t); 
    const bold = true;
    const ndfl = true;

    const add = (field, params) => {
        if (!params)
            params = {};
        let { bold, ndfl, valueTitle } = params;
        if (!bold)
            bold = false;
        if (!ndfl)
            ndfl = false;

        return [
            bondTitle[field],
            displayBond[field],
            bold,
            ndfl,
            tooltips[field],
            valueTitle
        ];
    };

    const params = {
        bond,
        add,
        isRedeemed,
        notRedeemed,
        taxSplitMode,
        putNotRedeemed,
        bold,
        ndfl
    };

    return (
        <div>
            <Container fluid>
                {type === "myBond" && bond.sellNow && <Container>
                    <Alert color="warning" className="my-3">
                        <h4 className="alert-heading">{t('BondCard.attention')}</h4>
                        <p>
                            {t('BondCard.sellNow') + displayBond.sellNowValue}
                        </p>
                    </Alert>
                </Container>}
                {isRedeemed && <div className="alignCenter">
                    <Alert color="danger" className="inlineBlock">
                        {t('BondCard.redeemed')}
                    </Alert>
                </div>}
                {putNotRedeemed && <div className="alignRight">
                    <Alert color="warning" className="inlineBlock">
                        {t('BondCard.putOffer')}
                    </Alert>
                </div>}
            </Container>
            {type !== "calc" && <DoubleChart bond={bond} />}
            <Container fluid>
                {type === "myBond" && <MyBondCard {...params} />}
                {type === "newBond" && <NewBondCard {...params} />}
                {type === "calc" && <CalcBondCard {...params} />}
            </Container>
            {type === "calc" && <DoubleChart bond={bond} />}
            <div className="alignCenter mt-3">
                <MoexButton bond={bond} />
            </div>
        </div>
    );
});