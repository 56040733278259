import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Header, BondCodeForm } from './modules';
import { Functions } from './functions.js';
import { useTranslation } from 'react-i18next';
import { BondCard } from './BondCard';

export const Calculator = () => {
   const { t } = useTranslation();
   const header = t('Calculator.header');
   const [calcBond, setCalcBond] = useState();
   const [invalid, setInvalid] = useState({
      status: false,
      message: ''
   });
   
   const processSubmit = async (code) => {
      return Functions.updateBond({ code }).then(bond => {
         bond = Functions.calculate(bond);
         setCalcBond(bond);
         Functions.setYandexGoal('calculator');
         setInvalid({
            ...invalid,
            status: false
         });
      }).catch(error => {
         setInvalid({
            status: true,
            message: t('BondDialog.errors.wrongCode')
         });
      });
   };

   return (
      <>
         <Header>{header}</Header>
         <Container>
            <div className="calculatorMaxWidth">
               <BondCodeForm processSubmit={processSubmit} invalid={invalid} setInvalid={setInvalid} button="calculate" />
            </div>
            {Functions.isSet(calcBond) && <>
               <h1 className="bondCardHeading mt-5">{Functions.getLocalBondName(calcBond)}</h1><BondCard bond={calcBond} type="calc" />
            </>}
         </Container>
      </>
   );
};