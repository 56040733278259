import React from 'react';
import { Header, FeedbackForm } from './modules';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';


export const Feedback = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Header>{t('Feedback.header')}</Header>
            <Container>
                <div className="mb-5 p-4 bg-white rounded shadow-sm greyColor">{t('Feedback.text')}</div>
                <FeedbackForm />
            </Container>
        </div>
    );
};