import React from 'react';
import { Link } from 'react-router-dom';
import { Header, InfoBlock } from './modules';
import { Jumbotron, Button, Container, Row, Col } from 'reactstrap';
import { Functions } from './functions.js';
import { useTranslation } from 'react-i18next';


export const Home = () => {
    const { t } = useTranslation();
    // const detailsClick = () => Functions.scrollTo('details');
    const moexSite = "https://www.moex.com/" + Functions.getCurrentLanguage();

    return (
        <div>
            <Jumbotron className="bg-light">
                <Header title={t('Home.title.header')} keywords={t('Home.title.keywords')} description={t('Home.title.description')}>{t('Home.header')}</Header>
                <p className="lead mt-5">{t('Home.text')}</p>
                <hr className="my-4" />
                {/* <Button color="info" outline size="lg" onClick={detailsClick}>{t('Buttons.details')}</Button> */}
                <Button tag={Link} to={'/calculator/'} color="info" outline size="lg">{t('Calculator.menuLink')}</Button>
            </Jumbotron>
            <Jumbotron className="bg-dark custromJumbotron" id="details">
                <Container fluid>
                    <Row className="justify-content-around">
                        <Jumbox name="findBonds" header={t('FindBonds.header')} t={t} />
                        <Jumbox name="newBonds" header={t('NewBonds.header')} t={t} />
                        <Jumbox name="myBonds" header={t('MyBonds.header')} t={t} />
                    </Row>
                </Container>
            </Jumbotron>
            <Container>
                <Row>
                    <InfoBlock name="Home.content.part1" t={t} />
                    <InfoBlock name="Home.content.part2" t={t}>
                        text <a href={moexSite} target="_blank" rel="noopener noreferrer">link</a> text
                    </InfoBlock>
                </Row>
            </Container>
        </div>
    );
};

const Jumbox = ({ name, header, t }) => {
    const text = "Home.promo." + name;

    return (
        <Col sm="6" lg="4" className="custromJumbotron-Col">
            <h2 className="heading">{header}</h2>
            <p className="lead whiteText my-4">{t(text)}</p>
            <p>
                <Button tag={Link} to={'/' + name + '/'} color="info" outline size="lg">{t('Buttons.go')}</Button>
            </p>
        </Col>
    );
};