import React from 'react';
import { Header } from './modules';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Functions } from './functions';


export const Privacy = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Header>{t('Privacy.header')}</Header>
            <Container>
                <Block textNumber={1} t={t} />
                <Block textNumber={2} t={t} />
                <Block textNumber={3} t={t} multiple={2} />
                <Block textNumber={4} t={t} />
                <Block textNumber={5} t={t} />
                <Block textNumber={6} t={t} />
                <Block textNumber={7} t={t} />
                <div className="greyColor privacyDate alignRight mt-3" >{t('Privacy.dateModified')}</div>
            </Container>
        </div>
    );
};

const Block = ({ textNumber, t, multiple }) => {
    const string = 'Privacy.content.part' + textNumber;
    let text;
    if (Functions.isSet(multiple)) {
        text = [];
        for (let i = 1; i <= multiple; i++)
            text.push(<p key={i}>{t(string + '.text.part' + i)}</p>);
    }
    else
        text = <p>{t(string + '.text')}</p>;
    return (
        <div className="my-3 p-4 bg-white rounded shadow-sm greyColor">
            <h4 className="mb-3" >{t(string + '.header')}</h4>
            {text}
        </div>
    );
};