import React, { useState, useMemo, memo } from 'react';
import { Container } from 'reactstrap';
import { Header, BondTable, LsWrap, ExtraColumns, Filters } from './modules';
import { Functions } from './functions.js';
import { connect, Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { bonds, currentSorting, allBonds, calculatedBonds, ndflFreeBonds, tableLoaded } from './store/reducers';
import { useTranslation } from 'react-i18next';


const reducer = combineReducers({
   bonds,
   currentSorting,
   allBonds,
   calculatedBonds,
   ndflFreeBonds,
   tableLoaded
});
const store = createStore(reducer);

export const BondScreener = () => {
   const { t } = useTranslation();

   return (
      <Provider store={store}>
         <Header title={t('BondScreener.title.header')} keywords={t('BondScreener.title.keywords')} description={t('BondScreener.title.description')}>{t('BondScreener.header')}</Header>
         <Text />
         <LsWrap>
            <FindBondsFilters />
            <FindBondsTable />
         </LsWrap>
      </Provider>
   );
};

let FindBondsFilters = ({ dispatch }) => {
   const readBoards = async () => {
      Functions.setYandexGoal('SearchUsed');
      try {
         let boards = ['TQOB', 'TQCB'];
         const baseUrl = "https://iss.moex.com/iss/engines/stock/markets/bonds/boards/";

         return Promise.all(boards.map(async board => {
            const url = baseUrl + board + "/securities.json";
            return fetch(url).then(response => response.json()).then(json => Functions.composeBondInfo(json));
         })).then(values => {
            const result = values.reduce((acc, cur) => acc.concat(cur), []);
            dispatch({ type: 'saveAllBonds', allBonds: result});
            return result;
         });
      }
      catch (error) {
         return [];
      }
   };

   return <Filters read={readBoards} />;
};
FindBondsFilters = connect(state => state)(FindBondsFilters);

const Text = () => {
   const { t } = useTranslation();
   // const lsName = 'findBondsTextVisible';
   // const lastVisible = !!window.localStorage[lsName];
   // const [visible, setVisible] = useState(lastVisible);
   
   // let buttonName;
   // if (visible)
   //    buttonName = 'Cкрыть описание';
   // else
   //    buttonName = 'Показать описание';

   // const toggle = () => {
   //    setVisible(!visible);
   //    window.localStorage[lsName] = Functions.boolForLs(!visible);
   // };

   return (
      <Container>
         {/* <Collapse isOpen={visible}> */}
            <p>
               {t('BondScreener.text.part1')}
            </p>
            <p>
               {t('BondScreener.text.part2')}
            </p>
            <p>
               {t('BondScreener.text.part3')}
            </p>
            <p className="mb-5">
               {t('BondScreener.text.part4')}
            </p>
         {/* </Collapse> */}
         {/* <div className="alignRight mb-3">
            <Button color="info" onClick={toggle} >{buttonName}</Button>
         </div> */}
      </Container>
   );
};

let FindBondsTable = ({ bonds, currentSorting, tableLoaded, dispatch }) => {
   const { t } = useTranslation();
   const [extraColumns, setExtraColumns] = useState(() => !!window.localStorage.extraColumns);
   const headers = useMemo(() => Functions.mapColumns(Functions.newAndFindBondsColumns(extraColumns), Functions.getTableColumns(t)), [extraColumns, t]);

   return (
      <div>
         <ExtraColumns extraColumns={extraColumns} setExtraColumns={setExtraColumns} />
         <BondTable {...{ bonds, headers, currentSorting, dispatch, TableRow, tableLoaded, deleteProp: 'code', extraColumns }} />
      </div>
   );
};
FindBondsTable = connect(state => state)(FindBondsTable);

const TableRow = memo(({ bond, extraColumns }) => {
   const { t } = useTranslation();
   if (!bond)
      return null;

   return (
      <tr>
         {Functions.mapRows(Functions.newAndFindBondsColumns(extraColumns), Functions.getRowValues(t, bond))}
      </tr>
   );
});