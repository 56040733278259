import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru/translation.json';
import en from './locales/en/translation.json';
import { Functions } from './functions';

const lang = Functions.getCurrentLanguage();

i18n
.use(initReactI18next)
.init({
    lng: lang,
    fallbackLng: lang,
    defaultNS: "translations",
    resources: {
        ru: {
          translations: ru
        },
        en: {
          translations: en
        }
    },
    interpolation: {
        escapeValue: false
    },
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'u']
    }
});

export default i18n;